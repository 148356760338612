import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Banded Good Mornings 4×12 & Single Leg RDL’s 4×6/leg`}</p>
    <p>{`Deadlifts 4×4\\@80%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`16:00 EMOM: (alternating movements each minute)`}</p>
    <p>{`1:00- 12 Deadlifts (185/125) (RX+ = 15 reps)`}</p>
    <p>{`2:00- 12 Box Jumps (24/20″) (RX+ = 15 reps)`}</p>
    <p>{`3:00- 12/8 Calorie Assault Bike (RX+ 15/10 calories)`}</p>
    <p>{`4:00- 400ft Run (RX+ 500ft Run)`}</p>
    <p>{`repeat for 4 rounds each.`}</p>
    <p>{`Score = total number of completed minutes`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      